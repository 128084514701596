import React from 'react';
import clsx from 'clsx';

interface Props {
  className?: string;
  children?: any;
  clean?: boolean;
}
// max-w-7xl
const Container: React.FC<Props> = ({className, children, clean}) => {
  const rootClassName = clsx(className, {
    'mx-auto  w-full px-5 md:px-10 xl:px-[5%] 2xl:px-[7.5%]': !clean,
  });

  return <div className={rootClassName}>{children}</div>;
};

export default Container;
